import "./diploma.scss";

import axios from "axios";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "reactstrap";

import { DocumentTypes } from "../../app/models/adminPanel.interface";
import { certificatesService } from "../../services/certificates.service";
import { ipfsService } from "../../services/ipfs.service";

import logo from "../../assets/icons/logoBlack.svg";
import pdfIcon from "../../assets/icons/pdf-icon.png";

import DeclineModal from "./DeclineModal/DeclineModal";
import DiplomaButtons from "./DiplomaButtons/DiplomaButtons";
import jwt from "jwt-decode";
import { useTranslation } from "react-i18next";
import { IJwt } from "../../constants";
import ConfirmModal from "./ConfirmModal/ConfirmModal";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const APP_PDF_TYPE = "application/pdf";

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

const ipfsUrl = process.env.REACT_APP_IPFS_URL;

export interface IDiploma {
  qr?: string;
  documentType?: DocumentTypes;
  instituteLogo?: any;
  institution?: string;
  physicalNumber?: string;
  student?: string;
  career?: string;
  diplomaFile?: any;
  transcriptFile?: any;
  graduationDate?: string;
  isOwner?: boolean;
  isApproved?: boolean;
  instituteId?: number;
}

const Diploma = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showQr, setShowQr] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [showDiploma, setShowDiploma] = useState(false);
  const [showTranscript, setShowTranscript] = useState(false);
  const [diplomaType, setDiplomaType] = useState("");
  const [transcriptType, setTranscriptType] = useState("");

  const [metadata, setMetadata] = useState("");

  const { t } = useTranslation();

  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [certificate, setCertificate] = useState<IDiploma>(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const toggleDiplomaPopup = () => {
    setShowDiploma(!showDiploma);
    setPageNumber(1);
  };

  const toggleTranscriptPopup = () => {
    setShowTranscript(!showTranscript);
    setPageNumber(1);
  };

  const toggleDeclineModal = () => {
    setIsDeclineModalOpen(prevState => !prevState);
  };
  const toggleConfirmModal = () => {
    setIsConfirmModalOpen(prevState => !prevState);
  };

  const parseDate = isoDate => {
    const timestamp = Date.parse(isoDate);
    return new Date(timestamp).toLocaleDateString("en-UK");
  };

  const parseIdFromUrl = (url: string) => {
    const arr = url.split("/");
    return arr[arr.length - 1];
  };

  useEffect(() => {
    const getCertificateData = async () => {
      const resp = await certificatesService.findCertificate(id);

      if (resp.error || resp.isBurned) {
        navigate("/invalid-diploma");
      }
      setMetadata(resp.metadata);

      const currentCertificate: IDiploma = {};

      try {
        const { accessToken } = JSON.parse(localStorage.getItem("tokens"));
        const decoded: IJwt = jwt(accessToken);

        currentCertificate.isOwner = decoded.id === resp.ownerId;
      } catch {
        currentCertificate.isOwner = false;
      }

      currentCertificate.instituteId = resp.instituteId;
      currentCertificate.qr = resp.QR;
      currentCertificate.documentType = resp.documentType;
      currentCertificate.instituteLogo = `${ipfsUrl + resp.instituteLogo}`;
      currentCertificate.isApproved = resp.isApproved;

      const { data: metadata } = await ipfsService.proxyIPFSrequest(
        process.env.REACT_APP_IPFS_URL + parseIdFromUrl(resp.metadata)
      );

      if (metadata.error) {
        navigate("/invalid-diploma");
        return;
      }

      currentCertificate.institution = metadata.institution_name;
      currentCertificate.physicalNumber = metadata.diploma_number;
      currentCertificate.student = metadata.student_name;
      currentCertificate.career = metadata.career;
      currentCertificate.graduationDate = parseDate(metadata.graduationDate);
      currentCertificate.diplomaFile =
        process.env.REACT_APP_IPFS_URL + parseIdFromUrl(metadata.diploma_image);

      const diplomaReq = await axios.get(process.env.REACT_APP_IPFS_URL + resp.diplomaIpfsHash);

      setDiplomaType(diplomaReq.headers["content-type"]);

      if (metadata.transcript_image !== null) {
        currentCertificate.transcriptFile = metadata.transcript_image;
        const transcriptReq = await axios.get(
          process.env.REACT_APP_IPFS_URL + resp.diplomaIpfsHash
        );
        setTranscriptType(transcriptReq.headers["content-type"]);
      } else {
        currentCertificate.transcriptFile = null;
      }

      setCertificate(currentCertificate);
    };

    getCertificateData();
  }, [id, navigate]);

  const declineHandler = () => {
    toggleDeclineModal();
  };

  const approveHandler = async () => {
    toggleConfirmModal();
  };

  if (!certificate) {
    return (
      <>
        <div className="container diploma__loader">
        <div className="block">
            <TailSpin
              ariaLabel="loading-indicator"
              color="#080341"
              wrapperClass="diploma__loader-indicator"
            />
            <p className="diploma__loader-text">{t("loading.loading")}</p>
          </div>
          <span className="text">
            If the page doesn't load, please refresh it
          </span>
        </div>
      </>
    );
  }

  return (
    <main className="container diploma">
      {!certificate.isApproved && certificate.isOwner && (
        <DiplomaButtons declineHandler={declineHandler} approveHandler={approveHandler} />
      )}
      <div className="diploma__wrapper">
        <div className="diploma__header">
          <div>
            <div className="diploma__logo">
              <img src={logo} alt="BitGraduate" />
            </div>
            <p>
              {t("diploma.description.first")}{" "}
              {certificate.documentType === DocumentTypes.diploma
                ? t("diploma.description.diploma")
                : t("diploma.description.certificate")}{" "}
              {t("diploma.description.second")}
            </p>
          </div>
          <div className="diploma__institute-logo">
            <img src={certificate.instituteLogo} alt={certificate.institution} />
          </div>
        </div>

        <div className="diploma__student-name">
          <p className="diploma__subtitle">{t("diploma.name")}</p>
          <h1 className="diploma__title">{certificate.student}</h1>
        </div>

        <div className="diploma__content">
          <div className="diploma__content-item">
            <p className="diploma__subtitle">{t("diploma.institution")}</p>
            <p className="diploma__text">{certificate.institution}</p>
          </div>
          <div className="diploma__content-item">
            <p className="diploma__subtitle">{t("diploma.career")}</p>
            <p className="diploma__text">{certificate.career}</p>
          </div>
          <div className="diploma__content-item">
            <p className="diploma__subtitle">{t("diploma.certificateId")}</p>
            <p className="diploma__text">{id}</p>
          </div>
          <div className="diploma__content-item">
            <p className="diploma__subtitle">{t("diploma.date")}</p>
            <p className="diploma__text">{certificate.graduationDate}</p>
          </div>
          <div className="diploma__content-item">
            <p className="diploma__subtitle">{t("diploma.physicalNumber")}</p>
            <p className="diploma__text">{certificate.physicalNumber}</p>
          </div>
        </div>

        <div className="diploma__footer">
          <div className="diploma__files-wrapper">
            <p>{t("diploma.files")}</p>
            <div className="diploma__files">
              <div className="diploma__files-item" onClick={() => setShowDiploma(true)}>
                <img
                  src={diplomaType === "application/pdf" ? pdfIcon : certificate.diplomaFile}
                  alt="file"
                />
                {t("diploma.document")}
              </div>
              {certificate.transcriptFile && (
                <div className="diploma__files-item" onClick={() => setShowTranscript(true)}>
                  <img
                    src={
                      transcriptType === "application/pdf" ? pdfIcon : certificate.transcriptFile
                    }
                    alt="file"
                  />
                  {t("diploma.transcript")}
                </div>
              )}
            </div>
          </div>

          <div className="diploma__qr">
            <img src={certificate.qr} alt="qr" onClick={() => setShowQr(true)} />
          </div>
        </div>
      </div>

      {/* QR MODAL */}
      <Modal isOpen={showQr} toggle={() => setShowQr(!showQr)} centered>
        <img src={certificate.qr} alt="qr" />
      </Modal>

      {/* DIPLOMA MODAL */}
      <Modal isOpen={showDiploma} toggle={toggleDiplomaPopup} centered className="diploma__modal">
        {diplomaType === APP_PDF_TYPE ? (
          <Document
            file={certificate.diplomaFile}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            <Page pageNumber={pageNumber} />
            <div className="page-controls">
              <button
                disabled={pageNumber <= 1}
                onClick={() => setPageNumber(pageNumber - 1)}
                type="button"
                aria-label="Previous page"
              >
                ‹
              </button>
              <span>
                {pageNumber} {t("diploma.of")} {numPages}
              </span>
              <button
                disabled={pageNumber >= numPages}
                onClick={() => setPageNumber(pageNumber + 1)}
                type="button"
                aria-label="Next page"
              >
                ›
              </button>
            </div>
          </Document>
        ) : (
          <img src={certificate.diplomaFile} alt="diploma" style={{ width: "inherit" }} />
        )}
      </Modal>

      {/* TRANSCRIPT MODAL */}
      {certificate.transcriptFile && (
        <Modal
          isOpen={showTranscript}
          toggle={toggleTranscriptPopup}
          centered
          className="diploma__modal"
        >
          {transcriptType === APP_PDF_TYPE ? (
            <Document
              file={certificate.transcriptFile}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
            >
              <Page pageNumber={pageNumber} />
              <div className="page-controls">
                <button
                  disabled={pageNumber <= 1}
                  onClick={() => setPageNumber(pageNumber - 1)}
                  type="button"
                  aria-label="Previous page"
                >
                  ‹
                </button>
                <span>
                  {pageNumber} {t("diploma.of")} {numPages}
                </span>
                <button
                  disabled={pageNumber >= numPages}
                  onClick={() => setPageNumber(pageNumber + 1)}
                  type="button"
                  aria-label="Next page"
                >
                  ›
                </button>
              </div>
            </Document>
          ) : (
            <img src={certificate.transcriptFile} alt="transcript" style={{ width: "inherit" }} />
          )}
        </Modal>
      )}

      {/* DECLINE MODAL */}
      <DeclineModal
        isDeclineModalOpen={isDeclineModalOpen}
        toggleDeclineModal={toggleDeclineModal}
        certificate={certificate}
        setCertificate={setCertificate}
      />
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        toggleModal={toggleConfirmModal}
        setCertificate={setCertificate}
      />
    </main>
  );
};

export default Diploma;
