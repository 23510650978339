import "./footer.scss";

import { Button } from "../Button/Button";
import { Logo } from "../Logo/Logo";

import joinButtonIcon from "../../assets/icons/main-page/header/join-button.svg";
import { useTranslation } from "react-i18next";
import { navigationLinks } from "../../constants";
import { scrollToElement } from "../../utils/scrollTo";
import { Link } from "react-router-dom";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="main-footer">
      <div className="container main-footer__wrapper">
        <div className="main-footer__navigation-wrapper">
          <Logo />

          {/* <Navigation /> */}
          <nav className="main-footer__navigation">
            {navigationLinks.map(link => (
              <Link
                key={link.title}
                to={link.href + link.hash}
                // onClick={() => scrollToElement(link.hash.slice(1))}
              >
                {link.title}
              </Link>
            ))}
          </nav>

          <div className="main-footer__button-wrapper">
            <Button className="main-footer__button" onClick={() => scrollToElement("contact-form")}>
              <img src={joinButtonIcon} alt="" />
              <p>{t("footer.join")}</p>
            </Button>
          </div>
        </div>

        <div className="main-footer__divider" />

        <div className="main-footer__text-wrapper">
          <p>{t("footer.description")}</p>
          <p>© 2024 BitGraduate</p>
        </div>
      </div>
    </footer>
  );
};
