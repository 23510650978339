import { Navigate, Route, Routes } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "./app/hooks/hooks";
import { setLoggedIn, setUserData } from "./app/state/userSlice";
import AdminsTable from "./pages/AdminPanel/AdminsTable";
import CareersTable from "./pages/AdminPanel/CareersTable";
import CertificatesTable from "./pages/AdminPanel/CertificatesTable";
import EmployeesTable from "./pages/AdminPanel/EmployeesTable";
import InstitutionsTable from "./pages/AdminPanel/InstitutionsTable";
import VerifyRequestsTable from "./pages/AdminPanel/VerifyRequestsTable";
import { Settings } from "./pages/AdminPanel/Settings";
import { AuthPage } from "./pages/Auth/AuthPage";
import Join from "./pages/Contact/Join";
import Support from "./pages/Contact/Support";
import Deactivated from "./pages/Deactivated";
import Diploma from "./pages/Diploma/Diploma";
import InvalidDiploma from "./pages/InvalidDiploma/InvalidDiploma";
import MainPage from "./pages/MainPage/MainPage";
import NewUserSettings from "./pages/NewUserSettings/NewUserSettings";
import SearchPage from "./pages/SearchPage/SearchPage";
import { StudentSettings } from "./pages/Student/Settings/StudentSettings";
import { StudentPage } from "./pages/Student/Student";
import { VerificationPage } from "./pages/Verification/VerificationPage";
import Admin from "./views/Admin";
import Main from "./views/Main";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import jwt from "jwt-decode";
import { IJwt } from "./constants";
import FacultiesTable from "./pages/AdminPanel/FacultiesTable";

function RequireAuth({ children }) {
  const tokens = localStorage.getItem("tokens");
  const dispatch = useAppDispatch();
  const isUserLogged = useAppSelector(state => state.user.isLogged);

  if (!tokens) {
    return <Navigate to="/auth" replace />;
  }
  if (!isUserLogged) {
    dispatch(setLoggedIn());
    dispatch(setUserData());
  }

  return children;
}

function App() {
  toast.configure({ pauseOnHover: false, autoClose: 3000, pauseOnFocusLoss: false });
  const { i18n } = useTranslation();

  useEffect(() => {
    let lang = null;
    const lsLang = localStorage.getItem("lang");

    try {
      const { accessToken } = JSON.parse(localStorage.getItem("tokens"));
      const decoded: IJwt = jwt(accessToken);
      lang = decoded.preferredLanguage;
    } catch {
      lang = null;
    }

    i18n.changeLanguage(lang ?? lsLang);
  }, [i18n]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="/" element={<MainPage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/verification" element={<VerificationPage />} />
          <Route path="/support" element={<Support />} />
          <Route path="/join" element={<Join />} />
          <Route path="/diploma/:id" element={<Diploma />} />
          <Route path="/student">
            <Route
              index
              element={
                <RequireAuth>
                  <StudentPage />
                </RequireAuth>
              }
            />
            <Route
              path="settings"
              element={
                <RequireAuth>
                  <StudentSettings />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/invalid-diploma" element={<InvalidDiploma />} />
        </Route>

        <Route path="/auth" element={<AuthPage />} />
        <Route
          path="/panel"
          element={
            <RequireAuth>
              <Admin />
            </RequireAuth>
          }
        >
          <Route path="admins" element={<AdminsTable />} />
          <Route path="institutions" element={<InstitutionsTable />} />
          <Route path="faculties" element={<FacultiesTable />} />
          <Route path="employees" element={<EmployeesTable />} />
          <Route path="careers" element={<CareersTable />} />
          <Route path="certificates" element={<CertificatesTable />} />
          <Route path="verify-requests" element={<VerifyRequestsTable />} />
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route path="auth/password/:token" element={<NewUserSettings />} />
        <Route path="auth/deactivated" element={<Deactivated />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
